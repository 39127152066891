import "./App.css";

function App() {
  return (
    <>
      <div className="">
        <article className="container">
          <main className="main">
            <div className="column-flex">
                {/* <h1 className="mainTitle">Dapp Mill</h1> */}
                <figure className="logo">
                  <img className="img" src="/logo/dappmillwhite.png" />
                </figure>
                <figure className="slogan">
                        {/* <img className="" src="/logo/bwc-blue.png" /> */}
                  </figure>
              <div className="header">
                <div className="heading">
                  <h1 className="title">DeFi Developers Hub</h1>
              
                  <p className="paragraph">
                    Turn your DeFi idea into reality with us in cheapest or no
                    cost of development by doing collaboration with us
                  </p>
                </div>
              </div>
              <div className="section">
                <h1 className="title2">Who are we?</h1>
                <p className="paragraph">
                  We're Web3, javascript and Blockchain Engineers with full expertise on
                  Ethereum & all EVM Blockchains like Binance Smart Chain,
                  Avalanche, Fantom, TRON & Layer 2 sidechains like Polygon{" "}
                </p>
                <p className="paragraph">
                  Web3 integration of smart contracts with ReactJS & Interactive
                  User Interface
                </p>
              </div>
              <div className="section core-expertise">
                <h1 className="title2 highlight">Core Expertise</h1>
                <p className="paragraph2">
                  - Network marketing protocols, MLM Matrix like Forsage, Binary plans <br/>
                  - ICOs, Reward Tokens like safemoon, Airdrops, Staking for Yield Farming <br/>
                  - NFTs Markets and Staking <br/>
                  - Arbitrage trading bots for DEXes with Flashloans <br/>
                  - DeFi project integration and Full upgradable smart contracts
                </p>
                {/* <p className="paragraph2">
                  We provide <strong> Network Marketing, ROI, MLM </strong> Best
                  Products with UI integration via Web3
                </p> */}
              </div>
              <div className="section">
                <h1 className="title2">Other Expertise</h1>
                <p className="paragraph">
                  Expertise in DEXes clone like SushiSwap, Pancakeswap etc<br/>
                </p>
                <p className="paragraph">
                  Expertise in making NFTs Minting programs and NFT Marketplaces.
                </p>
                <p className="paragraph">
                  Expertise in Web3 integration on Front end with Smart contract.
                </p>
                <p className="paragraph">
                  Expertise in Making Javascript application and websites and interactive interfaces.
                </p>
              </div>
              <div className="section">
                <h1 style={{marginBottom: '10px'}} className="title2">Top Projects</h1>
                <div className="row-flex">
                <article className="card">
                    <a href="https://solution.bid" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                        className="card-img" src="/logo/solution.png" />
                      </figure>
                      <p className="title3">Solution</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://bxchain.work/" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img"
                          src="/logo/bx.gif"
                        />
                      </figure>
                      <p className="title3">BX Chain</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://ilucky.cash" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}} className="card-img" src="/logo/l.png" />
                      </figure>
                      <p className="title3">ILucky Cash</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://metavm.biz" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}} className="card-img" src="/logo/metaversecash.png" />
                      </figure>
                      <p className="title3">Metaverse Money</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://eaglecash.io/" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img leopardo"
                          src="/logo/eaglecash.png"
                        />
                      </figure>
                      <p className="title3">Eagle Cash</p>
                    </a>
                  </article>
                  
                </div>
              </div>
              <div className="section">
                {/* <h1 style={{marginBottom: '10px'}} className="title2">Top Projects</h1> */}
                <div className="row-flex">
                <article className="card">
                    <a href="https://cmcash.net/" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img"
                          src="/logo/cmcash.png"
                        />
                      </figure>
                      <p className="title3">CM Cash</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://dgt.cmcash.net/" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img"
                          src="/logo/dgt.png"
                        />
                      </figure>
                      <p className="title3">DGT Coffee Plus</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://powersm.co" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img"
                          src="/logo/pos.png"
                        />
                      </figure>
                      <p className="title3">Power of Success</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://ox.cash" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                         className="card-img" src="/logo/oxcash-logo.png" />
                      </figure>
                      <p className="title3">OX CASH</p>
                    </a>
                  </article>
                  
                  <article className="card">
                    <a href="https://forcedmatrix.io" target="_blank">
                      <figure className="figure">
                        <img style={{ height: "200px"}}
                          className="card-img"
                          src="/logo/fmd.png"
                        />
                      </figure>
                      <p className="title3">Forced Matrix DAO</p>
                    </a>
                  </article>
                </div>
              </div>
              
              {/* <div className="section">
                <h1 style={{marginBottom: '10px'}} className="title2">DeFi Projects</h1>
                <div className="row-flex">
                  <article className="card">
                    <a href="https://metaversedao.app/" target="_blank">
                      <figure className="figure">
                        <img className="card-img" src="/logo/MetaverseDAO-logo.png" />
                      </figure>
                      <p className="title3">Metaverse DAO</p>
                    </a>
                  </article>
                </div>
              </div> */}
            </div>
          </main>
          <footer className="row-flex">
            <div className="contact">
              <div className="heading">
                <h2 className="title2">Contact Details</h2>
              </div>
              <div className="details">
                <i className="fa fa-envelope fa-2x"></i>
                <span className=""> Email: usmansoftdev@gmail.com</span>
              </div>
                <div className="details">
                  <i className="fa-brands fa-whatsapp fa-2x"></i>
                  <span> Mobile / Whatsapp: &nbsp;
                    <a style={{color: 'white'}} target="_blank" href="https://wa.me/+923059892189">
                      +92 3059892189
                    </a>
                  </span>
                </div>
                {/* <div className="details">
                  <i className="fa-brands fa-telegram fa-2x"></i>
                  <span> Telegram: &nbsp;
                  <a style={{color: 'white'}} target="_blank" href="https://t.me/uchained_official">@uchained_official</a>
                  </span>
                </div> */}
            </div>
            <div className="contact-2">
              <div className="heading">
                <h2 className="title2">Find Us On</h2>
              </div>
              <div className="details">
                <a target="_blank" href="https://www.fiverr.com/uchained?public_mode=true">
                  <figure className="figure-icon">
                    <img className="icon" src="/logo/fiverr-icon.png" />
                  </figure>
                </a>
                <a href="https://www.upwork.com/freelancers/~011614799d7ca539c1?viewMode=1">
                  <figure className="figure-icon">
                    <img className="icon" src="/logo/upwork-icon.png" />
                  </figure>
                </a>
              </div>
            </div>
          </footer>
        </article>
      </div>
    </>
  );
}

export default App;
