import React, { Component } from "react";
import withRouter from "../hooks/withWrapper";

class Portfolio extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="bg-animation">
          <article className="container">
            <main className="main">
              <div className="column-flex">
                <figure className="logo">
                  <img className="img" src="/logo/uchained-logo.webp" />
                </figure>
                <figure className="slogan">
                  <img className="" src="/logo/bwc-blue.png" />
                </figure>
                <div className="section">
                <h1 style={{marginBottom: '10px'}} className="title2">Best Network Marketing Projects for Clients</h1>
                <div className="row-flex">
                  <article className="card">
                    <a href="https://ox.cash" target="_blank">
                      <figure className="figure">
                        <img className="card-img" src="/logo/oxcash-logo.png" />
                      </figure>
                      <p className="title3">OX CASH</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://Leopardo.live" target="_blank">
                      <figure className="figure">
                        <img
                          className="card-img leopardo"
                          src="/logo/leopardo-logo.png"
                        />
                      </figure>
                      <p className="title3">Leopardo</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://Tigertron.io" target="_blank">
                      <figure className="figure">
                        <img
                          className="card-img"
                          src="/logo/tigertron-logo.png"
                        />
                      </figure>
                      <p className="title3">Tiger Tron</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://globe3.live" target="_blank">
                      <figure className="figure">
                        <img className="card-img" src="/logo/globe3-logo.png" />
                      </figure>
                      <p className="title3">Globe3</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://forcedmatrix.io" target="_blank">
                      <figure className="figure">
                        <img
                          className="card-img"
                          src="/logo/forcedmatrix3D-logo.jpg"
                        />
                      </figure>
                      <p className="title3">Forced Matrix DAO</p>
                    </a>
                  </article>
                  <article className="card">
                    <a href="https://polygonkingdom.io" target="_blank">
                      <figure className="figure">
                        <img
                          className="card-img"
                          src="/logo/polygon-kingdom-logo.png"
                        />
                      </figure>
                      <p className="title3">Polygon Kingdom ROI</p>
                    </a>
                  </article>
                </div>
                </div>
                <div className="section">
                    <h1 style={{marginBottom: '10px'}} className="title2">DeFi Projects</h1>
                    <div className="row-flex">
                    <article className="card">
                        <a href="https://metaversedao.app/" target="_blank">
                        <figure className="figure">
                            <img className="card-img" src="/logo/MetaverseDAO-logo.png" />
                        </figure>
                        <p className="title3">Metaverse DAO</p>
                        </a>
                    </article>
                    </div>
                </div>
              </div>
            </main>
            
          </article>
        </div>
      </>
    );
  }
}

export default withRouter(Portfolio);
